import React from 'react';
import NumberFormat from 'react-number-format';
// https://github.com/s-yadav/react-number-format
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaEnvelope, FaPhoneAlt, FaDesktop } from 'react-icons/fa';
import Layout from '../components/layout';
import SEO from '../components/seo';


function ContactPage() {
	return (
		<Layout>
			<SEO keywords={[ `Website Development`, `Website Design`, `Web Application`, `Data Analytics`, `Data Visualization`, `Freelance`, `Colin Willer` ]} title="Contact" />
			<section className="lg:mb-12 overflow-x-hidden">
				<div className="mb-8">
					<h1 className="mx-auto font-bold text-gray-900 my-2 text-2xl text-center md:w-2/3 lg:text-3xl xl:text-4xl xl:my-4">
						Looking for a quote or have a general question? Get in touch to learn how Willer Technologies
						can help!
					</h1>
					<p className="mx-auto text-gray-900 my-2 text-center text-sm md:w-2/3 md:text-md lg:text-lg xl:mt-4">
						Every project is different and it is important to understand the specific requirements for your
						project to come up with an accurate quote and estimated timeline. Understanding your project
						goals, specifications, and budget allows for better recommendations for how to deliver
						customized results that suit your specific needs. Please feel free to contact via email, phone,
						social media, or by filling out the form below to learn more!
					</p>
				</div>

				<div className="mb-12">
					<h3 className="text-3xl text-center text-wtblue font-bold text-gray-900 mb-2">
						Contact Information
					</h3>
					<div className="flex flex-col lg:flex-row justify-center w-full">
						<div className="flex-grow bg-wtblue py-2 lg:w-1/3 lg:py-16">
							<p className="block text-5xl text-center text-white">
								<FaEnvelope style={{ display: 'inline-block' }} />
							</p>
							<p className="block text-xl font-bold text-center text-white py-3 hover:text-gray-300 xl:text-2xl">
								<a href="mailto:info@willertechnologies.com">info@willertechnologies.com</a>
							</p>
						</div>
						<div className="flex-grow bg-gray-200 py-2 lg:w-1/3 lg:py-16">
							<p className="block text-5xl text-center text-wtblue">
								<FaPhoneAlt style={{ display: 'inline-block' }} />
							</p>
							<p className="block text-xl font-bold text-center text-wtblue py-3 hover:text-wtbluehover xl:text-2xl">
								<a href="tel:+19204609757">(920) 460-9757</a>
							</p>
						</div>
						<div className="flex-grow bg-wtblue py-2 lg:w-1/3 lg:py-16">
							<p className="block text-5xl text-center text-white">
								<FaDesktop style={{ display: 'inline-block' }} />
							</p>
							<ul className="flex flex-row justify-center py-3">
								<li className="text-3xl text-white pr-4 hover:text-gray-300 md:text-4xl xl:text-5xl">
									<a href="https://facebook.com" rel="noreferrer" target="_blank">
										<FaFacebookSquare />
									</a>
								</li>
								<li className="text-3xl text-white px-4 hover:text-gray-300 md:text-4xl xl:text-5xl">
									<a href="https://instagram.com" rel="noreferrer" target="_blank">
										<FaInstagramSquare />
									</a>
								</li>
								<li className="text-3xl text-white pl-4 hover:text-gray-300 md:text-4xl xl:text-5xl">
									<a href="https://linkedin.com" rel="noreferrer" target="_blank">
										<FaLinkedin />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				{/* https://cobwwweb.com/how-to-use-netlify-forms-with-gatsby */}
				<form
					name="Contact Form"
					method="POST"
					data-netlify="true"
					netlify-honeypot="bot-field"
					className="mx-auto md:w-2/3 xl:w-1/2"
				>

					<input type="hidden" name="form-name" value="Contact Form" className="hidden" />

					<p className="hidden">
						<label>
							Don’t fill this out if you are a real person: <input name="bot-field" />
						</label>
					</p>

					<label className="block mb-2 text-xs font-bold uppercase lg:text-sm xl:text-md" htmlFor="name">
						Name<span className="text-wtblue">*</span>
					</label>

					<input
						className="w-full mb-6 form-input border focus:border-wtblue focus:shadow-none"
						name="name"
						id="name"
						placeholder="John Doe"
						type="text"
						required
					/>

					<label className="block mb-2 text-xs font-bold uppercase lg:text-sm xl:text-md" htmlFor="phone">
						Phone Number
					</label>

					<NumberFormat name="phone" id="phone" className="w-full mb-6 form-input border focus:border-wtblue focus:shadow-none" format="+1 (###) ###-####" allowEmptyFormatting mask="_"/>

					{/* <input
						className="w-full mb-6 form-input border focus:border-wtblue focus:shadow-none"
						id="phone"
						placeholder="123-456-7890"
						type="text"
						required
					/> */}

					<label className="block mb-2 text-xs font-bold uppercase lg:text-sm xl:text-md" htmlFor="email">
						Email<span className="text-wtblue">*</span>
					</label>

					<input
						className="w-full mb-6 form-input border focus:border-wtblue focus:shadow-none"
						name="email"
						id="email"
						placeholder="johndoe@gmail.com"
						type="email"
					/>

					<label
						className="block mb-2 text-xs font-bold uppercase lg:text-sm xl:text-md"
						htmlFor="services-requested"
					>
						Services<span className="text-wtblue">*</span>
					</label>

					<select
						className="form-select w-full mb-6 form-input border focus:border-wtblue focus:shadow-none"
						name="services-requested"
						id="services-requested"
						placeholder="testing123"
						required
					>
						<option>Please select the option that best describes the services you are inquiring about</option>
						<option>A website for my business/organization</option>
						<option>A rebranding of the website currently used by my business/organization</option>
						<option>An online store to sell my products/services to customers</option>
						<option>A web application for my business/organization</option>
						<option>A data vizualization project</option>
						<option>A data analytics solution</option>
						<option>Multiple services offered above</option>
					</select>

					<label
						className="block mb-2 text-xs font-bold uppercase lg:text-sm xl:text-md"
						htmlFor="message"
						required
					>
						Message<span className="text-wtblue">*</span>
					</label>

					<textarea
						className="w-full mb-6 form-textarea border focus:border-wtblue focus:shadow-none"
						name="message"
						id="message"
						placeholder="Please provide any details you can about your specific project or any other questions you may have"
						rows="8"
					/>

					<button
						className="px-4 py-2 text-md font-bold text-white bg-wtblue rounded transition duration-500 ease-in-out hover:bg-wtbluehover xl:text-xl"
						type="submit"
					>
						Submit
					</button>
				</form>
			</section>
		</Layout>
	);
}

export default ContactPage;
